
.cardsBody .cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  justify-content: center;
  align-items: center;
  margin-top:50px;
  margin: auto;
  flex-wrap: wrap;
  max-width: 1200px;
}

.cardsBody .cardsContainer .card {
  position: relative;
  width: 320px;
  height: 350px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin:25px;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card:nth-child(1) .box .content a {
  background: #2196f3;
}

.cardsBody .cardsContainer .card:nth-child(2) .box .content a {
  background: #e91e63;
}

.cardsBody .cardsContainer .card:nth-child(3) .box .content a {
  background: #23c186;
}

.cardsBody .cardsContainer .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card .box:hover {
  transform: translateY(-30px);
}

.cardsBody .cardsContainer .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.cardsBody .cardsContainer .card .box .content {
  padding: 20px;
  text-align: center;
}

.cardsBody .cardsContainer .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.cardsBody .cardsContainer .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.cardsBody .cardsContainer .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.cardsBody .cardsContainer .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

@media screen and (max-width:1127px) {
    .cardsBody .cardsContainer .card{
        width:250px;
        height:300px
    }
}
@media screen and (max-width:568px) {
  .cardsBody .cardsContainer {
    display: grid;
    grid-template-columns: 1fr ;
  }
  .cardsBody .cardsContainer .card {
    position: relative;
  }
}