@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
@import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,300,300italic,400italic,600,700,600italic,700italic);
@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: "Alef Hebrew", sans-serif;
  white-space: pre-wrap;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.border-box{
    height:650px;
    display: flex;
    justify-content: center;
    margin-bottom: -55px;
}
.blog-card {
  width:100%;
  height: 650px;
  position: absolute;
  font-family: "Alef Hebrew", sans-serif;
  color:#fff;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  
}
.blog-card a{ 
  color:#fff;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card .color-overlay {
  width: 100%;
  height:100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card .gradient-overlay {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 100%;
  height: 500px;
  position: absolute;
  top: 400px;
  left: 0;
  z-index: 15;
}
.blog-card:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card:hover .card-info {
  opacity: 1;
  bottom: 100px;
}
.blog-card:hover .color-overlay {
  background: rgba(64, 64, 70,0.8);
}
.blog-card:hover .title-content{
  margin-top:90px;
  color: white;
  background-color: initial;
}
.title-content {
  text-align: center;
  margin: 200px auto auto auto;
  color: black;
  position: relative;
  z-index: 20;
  top: 0;
  background-color: rgb(244,244,244,0.6);
  max-width: 850px;
  width: 60%;
  border-radius: 15px;
  left: 0;
  transition:all 0.6s;
  
}

.blog-card:hover h3:after{
  -webkit-animation: changeLetter 0.3s 1 linear;
          animation: changeLetter 0.3s 1 linear;
  width:80%;
}

.blog-card .cardHeader{
  font-size: 85px;
  text-align: center;
  font-weight: 700;
  color: rgb(226, 103, 21);
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  position: relative;
  margin-top:5%;
  display:inline-block;
}

.blog-card h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s;
}

.cardHeader:after {
  content: " ";
  display: block; 
  width: 10%;
  height: 2px;
  margin: 2px auto;
  border: 0;
  background: #BDA26B;
  transition:all 0.2s
}

@-webkit-keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro {
  width: 170px;
  margin: 0 auto;
  direction: rtl;
  color: rgb(0, 0, 0);
  font-size: 30px;
  line-height: 30px;
}
.intro a{
  color: #ddd
}
.intro a:hover{
  text-decoration:underline;

}
.blog-card:hover .intro{
  display: none;
}

.card-info {
  box-sizing: border-box;
  padding: 0;
  width: 850px;
  direction: rtl;
  position: relative;
  margin: auto;
  padding: 0 50px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  transition: bottom 0.84s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
  font-size: 23px;
  font-weight: 400;
  top: 8%;
}
.utility-info a:hover{
  text-decoration:underline
}
.utility-list {
  list-style-type: none;
  margin: 0 0 10px 20px;
  padding: 0;
  width: 100%;

}
.utility-list li {
  margin: 0 5px 0 0;
  padding: 3px 0 15px 0px;
  display: inline-block;
  
  font-size:1.8em
}

@media (max-width:1000px)
{
  .blog-card .cardHeader{
    font-size: 65px;
  }
  .card-info {
    width: 90%;
    top: 10%;
  }
}

@media (max-width:750px)
{
  .utility-info {
    text-align:center;
  }
  .utility-info ul{
    width:100%;
    margin:0;
    box-sizing:border-box
  }
  .utility-info li{
    width:49%;
    display:inline-block;
    box-sizing:border-box;
    margin:0
  }
}
@media (max-width:613px)
{
  .title-content {
    margin: 180px auto auto auto;
  }
  .blog-card:hover .title-content{
    margin-top:30px;
  }
  .card-info {
    width: 90%;
    top: 5%;
  }
}

@media (max-width:500px){
  .blog-card .cardHeader{
    font-size: 55px;
  }
.intro{
  font-size:1em
}
.card-info{
  font-size: 17px;
}
}
@media (max-width:370px){
.blog-card h3{
  font-size:2.7em
}
.intro{
  font-size:1em
}
.card-info{
  font-size: 13px;
  font-weight: 600;
}
}
@media (max-width:310px){
.card-info{
  font-size: 11px;
}
}
@media (max-width:290px){
.card-info{
  font-size: 9px;
}
}
@media (max-width:260px){
.card-info{
  font-size: 8px;
}
}


.background {
    display: flex;
    direction: rtl;
    font-family: "Alef Hebrew", sans-serif;
    margin-top: 0%;
    
  }
  
  .container {
    width:80%;
    max-width:900px;
    margin:auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6);
    transition: 0.3s;
    border-radius: 15px;
  }
  
  .screen {
    position: relative;
    background: #e46510;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: relative;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    box-shadow: 0 20px 40px rgba(255, 255, 255, 0.4);
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1 1;
    color:#ffffff;
    padding: 50px;
  }
  
  .screen-body-item.left {
    display: flex;
    font-family: "Alef Hebrew", sans-serif;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    direction: rtl;
    font-family: "Alef Hebrew", sans-serif;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    font-size: 35px;
  }
  
  .app-title:after {
    content: '';
    font-family: "Alef Hebrew", sans-serif;
    display: block;
    position: relative;
    right: 0;
    bottom: -10px;
    width: 10%;
    height: 4px;
    background: #ffffff;
  }
  
  .app-contact {
    margin-top: auto;
    font-size: 8px;
    color: rgb(255, 255, 255);
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 50%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
  }
  
  .app-form-control::-webkit-input-placeholder {
    color: #666;
  }
  
  .app-form-control::placeholder {
    color: #666;
  }
  
  .app-form-control:focus {
    border-bottom-color: #ddd;
  }
  
  .app-form-button {
    background: none;
    border: none;
    color: #ea1d6f;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  
  .app-form-button:hover {
    color: #b9134f;
  }
  
  
  @media screen and (max-width: 520px) {
    .background {
      margin-bottom: 15%;
    }
    .screen-body {
      flex-direction: column;
    }
  
    .screen-body-item.left {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
  }
  
.pricingBody{
    margin-left: 7%;
    margin-top: 5%;
    margin-bottom: 5%;
}

.pricing-table {
  display: table;
  width: 100%;
  direction: rtl;
  
}
.pricing-table .pricing-option {
  width: 30%;
  background: rgb(39, 39, 39);
  float: left;
  border-radius: 5%;
  padding: 2%;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
.pricing-table .pricing-option:nth-child(even) {
  margin: 0 1%;
}
.pricing-table .pricing-option:hover {
  cursor: pointer;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.3);
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
}

.pricing-table .pricing-option:hover i,
.pricing-table .pricing-option:hover h1,
.pricing-table .pricing-option:hover span,
.pricing-table .pricing-option:hover b {
  color: #FF4500;
}

.pricing-table .pricing-option:hover .back {
  opacity: 1 !important;
  visibility: visible !important;
}
.pricing-table .pricing-option:hover .back a.button {
  -webkit-transform: translateY(0px) !important;
          transform: translateY(0px) !important;
}
.pricing-table .pricing-option hr {
  border: none;
  border-bottom: 1px solid #f0f0f0;
}
.pricing-table .pricing-option i {
  font-size: 3rem;
  color: #d8d8d8;
  transition: all 0.3s ease-in-out;
}
.pricing-table .pricing-option h1 {
  margin: 0px 0;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  height:60px;
  line-height: 30px;
}
.pricing-table .pricing-option p {
  color: rgb(197, 197, 197);
  padding: 0 10px;
  line-height: 1.3;
  font-size: 16px;
}
.pricing-table .pricing-option div {
  height:95px;
}
.pricing-table .pricing-option .price {
  position: relative;
}
.pricing-table .pricing-option .price .back a.button {
  background: #FF4500;
  padding: 15px 20px;
  display: inline-block;
  text-decoration: none;
  color: rgb(255, 255, 255);
  position: relative;
  font-size: 15px;
  left: 0;
  right: 0;
  width: 150px;
  margin: auto;
  text-transform: uppercase;
  -webkit-transform: scale(0.9,1);
          transform: scale(0.9,1);
  transition: all 0.6s ease-in-out;
  bottom: -30%;
}

@media screen and (max-width: 650px) {
  .pricing-table .pricing-option {
    padding: 5%;
    width: 90%;
  }
  .pricing-table .pricing-option:nth-child(even) {
    margin: 30px 0 !important;
  }
}





.coursemeal {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.coursemeal__div {
    flex: 0 0 33.3333333%;
    position: relative;
}

.responsive-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.coursemeal-info {
    background-color: rgba(0,0,0, 0.3);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.4s;
}

.coursemeal-info__link:link,
.coursemeal-info__link:visited {
    font-family: 'Abril Fatface', cursive;
    font-size: 30px;
    color: #fff;
    text-align: center;
    letter-spacing: 1.8px;
    margin: 0;
    text-decoration: none;

    transition: all 0.4s;
}

.coursemeal__div:hover .coursemeal-info {
    height: 100%;
    background-color: rgba(0,0,0, 0.6);
}



@media only screen and (max-width: 1200px) {
    

    .demo-description { 
        padding: 0;
        flex: 0 0 100%;
        text-align: center;
    }

}

@media only screen and (max-width: 991px) {
    .coursemeal  {
        height: 900px;
        flex-wrap: wrap;
    }

    .coursemeal__div {
        flex: 0 0 100%;
        height: 33.33333%;
    }

    .responsive-img img {
        height: 100%;
    }

    .coursemeal__div:nth-of-type(2) img {
        object-position: 50% 70%;
    }

    .coursemeal-info {
        height: 20%
    }
}

@media only screen and (max-width: 767px) {
    .coursemeal__div:nth-of-type(1) img {
        object-position: 50% 75%;
    }

    .coursemeal__div:nth-of-type(3) img {
        object-position: 50% 60%;
    }    

    .coursemeal-info__title {
        font-size: 25px;
    }    
}

.cardsBody .cardsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr ;
  justify-content: center;
  align-items: center;
  margin-top:50px;
  margin: auto;
  flex-wrap: wrap;
  max-width: 1200px;
}

.cardsBody .cardsContainer .card {
  position: relative;
  width: 320px;
  height: 350px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin:25px;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card:nth-child(1) .box .content a {
  background: #2196f3;
}

.cardsBody .cardsContainer .card:nth-child(2) .box .content a {
  background: #e91e63;
}

.cardsBody .cardsContainer .card:nth-child(3) .box .content a {
  background: #23c186;
}

.cardsBody .cardsContainer .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card .box:hover {
  -webkit-transform: translateY(-30px);
          transform: translateY(-30px);
}

.cardsBody .cardsContainer .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.cardsBody .cardsContainer .card .box .content {
  padding: 20px;
  text-align: center;
}

.cardsBody .cardsContainer .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.cardsBody .cardsContainer .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.cardsBody .cardsContainer .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.cardsBody .cardsContainer .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.cardsBody .cardsContainer .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

@media screen and (max-width:1127px) {
    .cardsBody .cardsContainer .card{
        width:250px;
        height:300px
    }
}
@media screen and (max-width:568px) {
  .cardsBody .cardsContainer {
    display: grid;
    grid-template-columns: 1fr ;
  }
  .cardsBody .cardsContainer .card {
    position: relative;
  }
}

  

  .footer {
    display: flex;
    flex-flow: row wrap;
    padding: 30px 30px 20px 30px;
    color: #ffffff;
    background-color: rgb(0, 0, 0);
    border-top: 1px solid #e5e5e5;
  }
  
  .footer > * {
    flex:  1 1 100%;
  }
  
  .footer__addr {
    margin-right: 1.25em;
    margin-bottom: 2em;
  }
  
  .footer__logo {
    font-weight: 400;
    text-transform: lowercase;
    font-size: 1.5rem;
  }
  
  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
    font-weight: 400;
  }
  
  .nav__title {
    font-weight: 400;
    font-size: 15px;
  }
  
  .footer address {
    font-style: normal;
    color: rgb(255, 255, 255);
  }
  
  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    max-width: -webkit-max-content;
    max-width: max-content;
    background-color: rgba(255, 255, 255, 0.07);
    border-radius: 100px;
    color: #ffffff;
    line-height: 0;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 0 1.3em;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    line-height: 2em;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer__nav {
    display: flex;
      flex-flow: row wrap;
  }
  
  .footer__nav > * {
    flex: 1 1 50%;
    margin-right: 1.25em;
  }
  
  .nav__ul a {
    color: rgb(255, 255, 255);
  }
  
  .nav__ul--extra {
    -webkit-column-count: 2;
            column-count: 2;
    grid-column-gap: 1.25em;
    -webkit-column-gap: 1.25em;
            column-gap: 1.25em;
  }
  
  .legal {
    display: flex;
    flex-wrap: wrap;
    color: rgb(255, 255, 255);
  }
    
  .legal__links {
    display: flex;
    align-items: center;
  }
  
  .heart {
    color: #ffffff;
  }
  
  @media screen and (min-width: 24.375em) {
    .legal .legal__links {
      margin-left: auto;
    }
  }
  
  @media screen and (min-width: 40.375em) {
    .footer__nav > * {
      flex: 1 1;
    }
    
    .nav__item--extra {
      flex-grow: 2;
    }
    
    .footer__addr {
      flex: 1 1;
    }
    
    .footer__nav {
      flex: 2 1;
    }
  }

*, *:before, *:after {
	 box-sizing: border-box;
	 outline: none;
	 
}

.staff-layout{
	margin-top:-8%;
	margin-bottom:0%;
}

.cards-layout{
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(3, 33%);
  grid-row-gap: 4%;
  grid-column-gap: 6%;
  padding: 15%;
  margin-bottom: 5%;
 

}

 .worker-card{
	 font-family: 'Roboto', sans-serif;
	 line-height: 1.5;
	 color: #444;
}


.worker-card-body  {
	 position: relative;
	 display: flex;
	 align-items: center;
	 justify-content: center;
	 width: 105%;
	 height: 100%;
	 border-radius: 5%;
	 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	 transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
	 overflow: hidden;
	 
}

.worker-card-body :hover {
	 box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.worker-card-body :hover .worker-image {
	 -webkit-transform: scale(1.25);
	         transform: scale(1.25);
}

.worker-card-body :hover figcaption {
	 bottom: 0;
}

 .worker-image {
	 margin-top:-10% ;
	 margin-left: -20%;
	 margin-bottom:-10% ;
	 transition: 0.25s;
	 width:140%;
	 height:100%;
}

 .worker-details {
	 position: absolute;
	 font-family: "Alef Hebrew", sans-serif;
	 bottom: -54%;
	 left: 0;
	 height:73%;
	 width: 100%;
	 padding: 20px;
	 background-color: rgba(0, 0, 0, 0.85);
	 box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
	 color: white;
	 line-height: 1;
	 transition: 0.25s;
	 text-align: right;
}

.details-header {
	 margin: 0 0 0px;
	 padding: 0;
	 text-align: center;
}

 .details-text {
     font-size: 13px;
	 margin-top: 10%;
	 direction: rtl;
	 line-height: 1.5;
}
.read-more{
	margin-top: 5px;
}

@media screen and (min-width: 2600px) {
	.details-text{
	font-size: 15px ;
	}
	.worker-details {
		bottom: -64%;
   }
}
@media screen and (max-width: 1600px) {
	.details-text{
	font-size: 10px ;
	}
}

@media screen and (max-width: 1385px) {
	.staff-layout{
		margin-top:-8%;
		margin-bottom:50%;
	}
	.cards-layout{
		padding: 7%;
		
		grid-row-gap: 5%;
	  }
	.worker-details {
	    bottom: -54%;
		text-align: right;
		font-size: 13px;
   }
   .details-text{
	font-size: 12px ;
	}
	.worker-image {
		transition: 0.25s;
   }
   .cards-layout{
	grid-template-columns: repeat(2, 35%);
  }
}
@media screen and (max-width: 1040px) {
	.details-text{
	font-size: 10px ;
	}
}
@media screen and (max-width: 860px) {
   .worker-details {
	    bottom: -55%;
		text-align: right;
		font-size: 70%;
   }
   .cards-layout{
	    grid-template-columns: repeat(2, 50%);
   }
   .details-text{
	font-size: 12px ;
	}
   .worker-name{
	    top: 2px;
	    left: 6px;
   }
}

@media screen and (max-width: 660px) {
	.details-text{
	font-size: 8px ;
	}
}


@media screen and (max-width: 570px) {
	.cards-layout{
		display: block;
		padding: 15%;
		
	  }
	  .details-text{
		font-size:10px ;
		}
	.worker-card-body{
		margin-bottom: 10%;
		font-size: 73%;
		height: 350px;
	}
	.details-header {
		margin: 0 0 5px;
    }
	.worker-image {
		transition: 0.25s;
		margin-left: -0%;
		height: 400px;
		width:auto
   }
   .worker-details {
		text-align: right;
		font-size: 90%;
	    bottom: -56%;
   }
   
}
@media screen and (max-width: 450px) {
   .worker-details {
	    bottom: -54%;
   }
   .details-text{
	font-size: 11px ;
	}
   
}
@media screen and (max-width: 350px) {
   .details-text{
	font-size: 10px ;
	}
   
}
@media screen and (max-width: 300px) {
   .details-text{
	font-size: 9px ;
	}
	.read-more{
		margin-top: 8px;
	}
   
}

