@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);
  
  .background {
    display: flex;
    direction: rtl;
    font-family: "Alef Hebrew", sans-serif;
    margin-top: 0%;
    
  }
  
  .container {
    width:80%;
    max-width:900px;
    margin:auto;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.6);
    transition: 0.3s;
    border-radius: 15px;
  }
  
  .screen {
    position: relative;
    background: #e46510;
    border-radius: 15px;
  }
  
  .screen:after {
    content: '';
    display: block;
    position: relative;
    top: 0;
    left: 20px;
    right: 20px;
    bottom: 0;
    box-shadow: 0 20px 40px rgba(255, 255, 255, 0.4);
  }
  
  .screen-header {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: #ffffff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .screen-body {
    display: flex;
  }
  
  .screen-body-item {
    flex: 1;
    color:#ffffff;
    padding: 50px;
  }
  
  .screen-body-item.left {
    display: flex;
    font-family: "Alef Hebrew", sans-serif;
    flex-direction: column;
  }
  
  .app-title {
    display: flex;
    direction: rtl;
    font-family: "Alef Hebrew", sans-serif;
    flex-direction: column;
    position: relative;
    color: #ffffff;
    font-size: 35px;
  }
  
  .app-title:after {
    content: '';
    font-family: "Alef Hebrew", sans-serif;
    display: block;
    position: relative;
    right: 0;
    bottom: -10px;
    width: 10%;
    height: 4px;
    background: #ffffff;
  }
  
  .app-contact {
    margin-top: auto;
    font-size: 8px;
    color: rgb(255, 255, 255);
  }
  
  .app-form-group {
    margin-bottom: 15px;
  }
  
  .app-form-group.message {
    margin-top: 40px;
  }
  
  .app-form-group.buttons {
    margin-bottom: 0;
    text-align: right;
  }
  
  .app-form-control {
    width: 50%;
    padding: 10px 0;
    background: none;
    border: none;
    border-bottom: 1px solid #666;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
  }
  
  .app-form-control::placeholder {
    color: #666;
  }
  
  .app-form-control:focus {
    border-bottom-color: #ddd;
  }
  
  .app-form-button {
    background: none;
    border: none;
    color: #ea1d6f;
    font-size: 14px;
    cursor: pointer;
    outline: none;
  }
  
  .app-form-button:hover {
    color: #b9134f;
  }
  
  
  @media screen and (max-width: 520px) {
    .background {
      margin-bottom: 15%;
    }
    .screen-body {
      flex-direction: column;
    }
  
    .screen-body-item.left {
      margin-bottom: 30px;
    }
  
    .app-title {
      flex-direction: row;
    }
  
    .app-title span {
      margin-right: 12px;
    }
  
    .app-title:after {
      display: none;
    }
  }
  
  @media screen and (max-width: 600px) {
    .screen-body {
      padding: 40px;
    }
  
    .screen-body-item {
      padding: 0;
    }
  }
  