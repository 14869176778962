


.coursemeal {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.coursemeal__div {
    flex: 0 0 33.3333333%;
    position: relative;
}

.responsive-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.coursemeal-info {
    background-color: rgba(0,0,0, 0.3);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    display: flex; 
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transition: all 0.4s;
}

.coursemeal-info__link:link,
.coursemeal-info__link:visited {
    font-family: 'Abril Fatface', cursive;
    font-size: 30px;
    color: #fff;
    text-align: center;
    letter-spacing: 1.8px;
    margin: 0;
    text-decoration: none;

    transition: all 0.4s;
}

.coursemeal__div:hover .coursemeal-info {
    height: 100%;
    background-color: rgba(0,0,0, 0.6);
}



@media only screen and (max-width: 1200px) {
    

    .demo-description { 
        padding: 0;
        flex: 0 0 100%;
        text-align: center;
    }

}

@media only screen and (max-width: 991px) {
    .coursemeal  {
        height: 900px;
        flex-wrap: wrap;
    }

    .coursemeal__div {
        flex: 0 0 100%;
        height: 33.33333%;
    }

    .responsive-img img {
        height: 100%;
    }

    .coursemeal__div:nth-of-type(2) img {
        object-position: 50% 70%;
    }

    .coursemeal-info {
        height: 20%
    }
}

@media only screen and (max-width: 767px) {
    .coursemeal__div:nth-of-type(1) img {
        object-position: 50% 75%;
    }

    .coursemeal__div:nth-of-type(3) img {
        object-position: 50% 60%;
    }    

    .coursemeal-info__title {
        font-size: 25px;
    }    
}