@import url(https://fonts.googleapis.com/earlyaccess/alefhebrew.css);


.border-box{
    height:650px;
    display: flex;
    justify-content: center;
    margin-bottom: -55px;
}
.blog-card {
  width:100%;
  height: 650px;
  position: absolute;
  font-family: "Alef Hebrew", sans-serif;
  color:#fff;
  overflow: hidden;
  border-radius: 0px;
  box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
  text-align: center;
  transition:all 0.4s;
  
}
.blog-card a{ 
  color:#fff;
  text-decoration:none;
  transition:all 0.2s
}
.blog-card .color-overlay {
  width: 100%;
  height:100%;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}
.blog-card .gradient-overlay {  
  background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
  width: 100%;
  height: 500px;
  position: absolute;
  top: 400px;
  left: 0;
  z-index: 15;
}
.blog-card:hover{
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}
.blog-card:hover .card-info {
  opacity: 1;
  bottom: 100px;
}
.blog-card:hover .color-overlay {
  background: rgba(64, 64, 70,0.8);
}
.blog-card:hover .title-content{
  margin-top:90px;
  color: white;
  background-color: initial;
}
.title-content {
  text-align: center;
  margin: 200px auto auto auto;
  color: black;
  position: relative;
  z-index: 20;
  top: 0;
  background-color: rgb(244,244,244,0.6);
  max-width: 850px;
  width: 60%;
  border-radius: 15px;
  left: 0;
  transition:all 0.6s;
  
}

.blog-card:hover h3:after{
  animation: changeLetter 0.3s 1 linear;
  width:80%;
}

.blog-card .cardHeader{
  font-size: 85px;
  text-align: center;
  font-weight: 700;
  color: rgb(226, 103, 21);
  text-shadow: 2px 2px 2px rgb(0, 0, 0);
  position: relative;
  margin-top:5%;
  display:inline-block;
}

.blog-card h3 a{  
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);  
  transition:all 0.2s;
}

.cardHeader:after {
  content: " ";
  display: block; 
  width: 10%;
  height: 2px;
  margin: 2px auto;
  border: 0;
  background: #BDA26B;
  transition:all 0.2s
}

@keyframes changeLetter {
  0% {
    width: 10%;
  }
  100% {
    width: 80%;
  }
}

.intro {
  width: 170px;
  margin: 0 auto;
  direction: rtl;
  color: rgb(0, 0, 0);
  font-size: 30px;
  line-height: 30px;
}
.intro a{
  color: #ddd
}
.intro a:hover{
  text-decoration:underline;

}
.blog-card:hover .intro{
  display: none;
}

.card-info {
  box-sizing: border-box;
  padding: 0;
  width: 850px;
  direction: rtl;
  position: relative;
  margin: auto;
  padding: 0 50px;
  line-height: 24px;
  z-index: 20;
  opacity: 0;
  transition: bottom 0.84s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
  font-size: 23px;
  font-weight: 400;
  top: 8%;
}
.utility-info a:hover{
  text-decoration:underline
}
.utility-list {
  list-style-type: none;
  margin: 0 0 10px 20px;
  padding: 0;
  width: 100%;

}
.utility-list li {
  margin: 0 5px 0 0;
  padding: 3px 0 15px 0px;
  display: inline-block;
  
  font-size:1.8em
}

@media (max-width:1000px)
{
  .blog-card .cardHeader{
    font-size: 65px;
  }
  .card-info {
    width: 90%;
    top: 10%;
  }
}

@media (max-width:750px)
{
  .utility-info {
    text-align:center;
  }
  .utility-info ul{
    width:100%;
    margin:0;
    box-sizing:border-box
  }
  .utility-info li{
    width:49%;
    display:inline-block;
    box-sizing:border-box;
    margin:0
  }
}
@media (max-width:613px)
{
  .title-content {
    margin: 180px auto auto auto;
  }
  .blog-card:hover .title-content{
    margin-top:30px;
  }
  .card-info {
    width: 90%;
    top: 5%;
  }
}

@media (max-width:500px){
  .blog-card .cardHeader{
    font-size: 55px;
  }
.intro{
  font-size:1em
}
.card-info{
  font-size: 17px;
}
}
@media (max-width:370px){
.blog-card h3{
  font-size:2.7em
}
.intro{
  font-size:1em
}
.card-info{
  font-size: 13px;
  font-weight: 600;
}
}
@media (max-width:310px){
.card-info{
  font-size: 11px;
}
}
@media (max-width:290px){
.card-info{
  font-size: 9px;
}
}
@media (max-width:260px){
.card-info{
  font-size: 8px;
}
}

